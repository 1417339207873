import React, { useContext, useEffect, useState } from "react";
import { BiSolidCookie } from "react-icons/bi";
import { LanguageContext } from '../language.context';

//Mensagem de aceitar ou rejeitar cookies
const CookiesNotification = () => {
    const { strings } = useContext(LanguageContext);

    const [accepted, setAccepted] = useState("");

    //Função caso o user aceite cookies
    const handleAcceptCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setAccepted(true);
    };
    //Função caso o user rejeite cookies
    const handleRejectCookies = () => {
        localStorage.setItem("cookiesAccepted", "false");
        setAccepted(false);
    };

    useEffect(() => {

    }, [accepted])

    if (localStorage.getItem("cookiesAccepted")) {
        return null; // Don't render the disclaimer if already accepted
    }

    if (strings) {
        return (
            <div className="fixed inset-0 z-50 flex items-end justify-end">
                <div className="flex flex-col items-center max-w-sm px-8 py-5 mx-5 my-5 cardGradient4 shadow-2xl sm:py-7 rounded-2xl">
                    <BiSolidCookie className="mb-2 text-4xl text-white sm:text-5xl sm:mb-5" />
                    <h1 className="mb-2 text-xl text-white sm:mb-4 sm:text-heading2">{strings.Cookies.title}</h1>
                    <div className="flex flex-col text-white  text-justify">
                        <div className="text-xs sm:text-base">
                            {strings.Cookies.description}
                        </div>
                        <div className="mt-2 text-xs sm:text-base">
                            {strings.Cookies.description2}
                            <a href="/Privacy" className="underline cursor-pointer">{strings.home.footer.privacyPolicy}</a>.
                        </div>
                    </div>

                    <div className="flex w-full">
                        <button onClick={handleAcceptCookies} className="p-2 w-full px-8 mt-6 font-bold tracking-wider text-white uppercase transition-opacity sm:p-3 sm:px-10 bg-gradient-to-r darkOrangeGradient text-buttonText hover:opacity-75" style={{ clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)' }}>
                            {strings.Cookies.accept}
                        </button>
                    </div>

                </div>
            </div>
        );
    }
};

export default CookiesNotification;
