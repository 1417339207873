import React, { useContext, useState } from 'react';
import { LanguageContext } from '../language.context';

const FormSubmited = ({ setSubmitted }) => {
    const { strings } = useContext(LanguageContext);
    const [isFadingOut, setIsFadingOut] = useState(false);

    const handleClose = () => {
        // Trigger the fade-out animation
        setIsFadingOut(true);

        // Wait for the animation to finish before updating the state
        setTimeout(() => {
            setSubmitted(false);
        }, 1000); // Adjust the timeout to match your animation duration
    };

    setTimeout(() => {
        handleClose()
    }, 5000)

    return (
        <div className={`absolute z-20 bottom-0 top-0 left-0 right-0 ${isFadingOut ? 'fade-out' : 'fade-in'} w-full`}>
            <div className='flex items-center justify-center w-full h-full'>
                <div className='flex flex-col'>
                    <div className='relative h-full shadow-lg rounded-xl borderFormSubmited'>
                        <div className='relative z-10 flex flex-col items-center h-full p-12 px-6 sm:px-12 cardGradient1 '>
                            <h1 className='text-xl font-bold text-center text-white xs:text-3xl'>
                                {strings.FormSuccess.title}
                            </h1>
                            <button
                                onClick={handleClose}
                                className="inline-block w-40 p-3 px-10 mx-auto font-bold tracking-wider text-center text-white uppercase transition-opacity mt-7 bg-gradient-to-r darkOrangeGradient text-buttonText hover:opacity-75"
                                style={{ clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)' }}
                            >
                                {strings.FormSuccess.button}
                            </button>
                        </div>
                        <div className="top"></div>
                        <div className="bottom"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormSubmited;
