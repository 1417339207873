import React, { useContext } from 'react';
import { LanguageContext } from '../language.context';

const Partners = () => {

    const { strings } = useContext(LanguageContext);

    return (
        <section id='services' className='mt-12'>
            <h1 className='mt-6 font-bold tracking-wider text-center uppercase text-darkGray sm:mt-0'>{strings.home.partners.heading}</h1>
            <div className='flex justify-center'>
                <div className='grid flex-col gap-x-24 items-center grid-cols-1 mt-8 w-1/2 xl:w-auto sm:grid-cols-2 xl:flex xl:flex-row gap-y-8'>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xl:h-10 xl:w-auto select-none convertImageColor w-[150px] xl:mt-0 mt-2' src={"./images/parteners/tokio.png"} alt='Tokio Logo' />
                    </div>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xl:h-10 xl:w-auto select-none convertImageColor w-auto h-[50px] xl:mt-0' src={"./images/parteners/ironhack.png"} alt='IronHack' />
                    </div>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xl:h-10 xl:w-auto select-none convertImageColor w-auto h-[50px] xl:mt-0' src={"./images/parteners/bullzgames.png"} alt='Bullz Games' />
                    </div>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xl:h-10 xl:w-auto select-none convertImageColor w-auto h-[50px] xl:mt-0' src={"./images/parteners/feat-logo.png"} alt='feat Logo' />
                    </div>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xxl:h-10 xl:w-auto select-none convertImageColor w-auto h-[50px] xl:mt-0' src={"./images/parteners/castroTravel.png"} alt='Castro Travel Logo' />
                    </div>
                    <div className='xl:w-auto w-[200px] xl:h-auto h-[50px] xl:mx-0 mx-auto justify-center flex'>
                        <img className='xl:h-10 xl:w-auto select-none convertImageColor w-auto h-[50px] xl:mt-0' src={"./images/parteners/creation.png"} alt='Creation Logo' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;
