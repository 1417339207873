import React, { useContext } from 'react';
import { LanguageContext } from '../language.context';
import { MdOutlineMail, MdOutlineMessage } from "react-icons/md";
import { FiInstagram } from 'react-icons/fi';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';

const Footer = () => {

    const { strings } = useContext(LanguageContext);

    return (
        <footer className='relative flex justify-center w-full mt-32 bg-gradient-to-r yellowOrangeGradient'>
            <div className='xl:w-[1920px] '>
                <span className='-ml-1 xs:-ml-3 md:-ml-5  w-full text-[19vw] 2xl:text-[19rem] inline-block font-TitilliumWeb-Black text-center font-bold italic uppercase text-[#1b1b1b] select-none leading-[2rem] xs:leading-[2.5rem] sm:leading-[3rem] md:leading-[5rem] lg:leading-[6.5rem] xl:leading-[10rem]'>
                    Dionamite
                </span>
                <div className='grid grid-cols-1 mx-8 mt-8 md:grid-cols-2 lg:grid-cols-4 lg:mx-32 md:mt-16'>
                    <div className='flex flex-col justify-start'>
                        <img className='w-48' src='images/logo/logoComNome.svg' alt='Logo Dionamite' />
                        <p className='mt-4 font-medium tracking-wide text-smallP'>
                            {strings.home.footer.portugal}
                        </p>
                        <img className='mt-2 w-9' src='images/logo/portugal.png' alt='Portugal' />
                    </div>
                    <div className='flex flex-col'>
                        <h6 className='mt-6 font-bold text-heading5 sm:mt-0'>Menu</h6>
                        <a href="/#services" className='mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                            {strings.home.footer.nav1}
                        </a>
                        <a href="/#portfolio" className='mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                            {strings.home.footer.nav2}
                        </a>
                        <a href="/#team" className='mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                            {strings.home.footer.nav3}
                        </a>
                    </div>
                    <div>
                        <h6 className='mt-6 font-bold text-heading5 lg:mt-0'>{strings.home.footer.header1} </h6>
                        <a href="mailto:hello@dionamite.com" className='flex items-center mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                            <MdOutlineMail className='mr-2 text-base' /> hello@dionamite.com
                        </a>
                        <a href='tel:+351 917 549 262' className='flex items-center mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                            <MdOutlineMessage className='mr-2 text-base' />  pt +351 917 549 262
                        </a>
                        {/* <a href='tel:+55 (73) 99801-0433' className='flex items-center mt-4 font-bold uppercase transition-opacity text-buttonText opacity-70 hover:opacity-100'>
                        <MdOutlineMessage className='mr-2 text-base' /> br +55 (73) 99801-0433
                    </a> */}
                    </div>
                    <div>
                        <h6 className='mt-6 font-bold text-heading5 lg:mt-0 '>{strings.home.footer.header2}</h6>
                        <div className='flex gap-8 mt-4 opacity-70'>
                            {/* <a href='https://www.instagram.com/dionamite_/' target="_blank" className='transition-opacity opacity-70 hover:opacity-100'>
                                <FiInstagram className='text-3xl' />
                            </a> */}
                            <a href='https://www.linkedin.com/company/dionamite/' target="_blank" className='transition-opacity opacity-70 hover:opacity-100'>
                                <FaLinkedin className='text-3xl' />
                            </a>
                            {/* <a href='https://www.facebook.com/dionamiteofficial' target="_blank" className='transition-opacity opacity-70 hover:opacity-100'>
                                <FaFacebook className='text-3xl' />
                            </a> */}
                        </div>
                    </div>
                </div>
                <div className='border-t border-[#000] border-opacity-20 w-full mx-auto mt-8 '>
                    <div className='flex flex-col items-center justify-center gap-1 py-8 sm:flex-row '>
                        <p className='font-semibold text-center opacity-100 text-smallP'>
                            {`Dionamite 2024 - ${strings.home.footer.rights}  `}
                        </p>
                        <a href="/privacy" className='font-bold text-center underline opacity-100 sm:mt-0 text-smallP'>
                            {strings.home.footer.privacyPolicy}
                        </a>
                        <a href="https://www.livroreclamacoes.pt/inicio/" target='_blank' className='font-bold text-center underline opacity-100 sm:mt-0 text-smallP'>
                            {strings.home.footer.complaintsbook}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
